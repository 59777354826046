<template>
    <div class="protfolio-item">
        <div class="banner-item-box">
            <img src="@/assets/images/protfolio-banner-item.png"/>
        </div>

        <div class="message-box">
            <img src="@/assets/images/protfolio-item-1.png"/>

            <div class="info-box">
                <div class="title">Elucid Social</div>
                <div class="link">https://www.crunchbase.com/organization/elucid-social</div>
                <div class="note">Elucid Social integrates businesses with the social good movement through a data-driven strategy that yields measurable results.</div>
                <div class="mark">
                    <div class="text">Invested</div>
                    <div class="date">Participate in the 2024-1-26 Seed round</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.message-box {
    display: flex;

    position: relative;
    width: 100%;
    height: 480px;
    background-color: #FFFFFF;
    padding: 92px 0 102px 458px;

    > img {
        position: absolute;

        top: -78px;
        left: 118px;
        width: 296px;
        height: 296px;
    }

    .info-box {
        display: flex;
        flex-direction: column;

        width: 804px;

        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 31px;
            color: #00457A;
            margin-bottom: 21px;
        }

        .link {
            font-size: 22px;
            font-weight: 400;
            line-height: 31px;
            color: #00457A;
            margin-bottom: 30px;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }

        .note {
            font-size: 22px;
            font-weight: 300;
            line-height: 31px;
            color: #1E1E1E;
            margin-bottom: 34px;
        }

        .mark {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            width: 100%;
            height: 74px;

            .text {
                font-size: 20px;
                font-weight: 300;
                line-height: 31px;
                color: #1E1E1E;
            }

            .date {
                font-size: 20px;
                font-weight: 400;
                line-height: 31px;
                color: #00457A;
            }
        }
    }
}
</style>